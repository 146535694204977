import { mapGetters } from 'vuex'

export default {
	data() {
		return {
			error: ''
		}
	},
	computed: {
		...mapGetters([
			// stepA
			'getClinic',
			'getAppointmentDate',
			'getAppointmentTime',
			'getReasonForVisit',
			'getOtherReasonForVisit',
			// stepB
			'getFirstName',
			'getLastName',
			'getPreferredName',
			'getDateOfBirth',
			'getFullPostcode',
			'getContactNumber',
			'getEthnicity',
			'getOtherEthnicity',
			'getSexuality',
			'getOtherSexuality',
			'getSexAssignedAtBirth',
			'getPreferredPronoun',
			'getOtherPreferredPronoun',
			// stepC
			'getPartners',
			'getPartnersAge',
			'getGenitals',
			'getHasHadSex',
			'getHasHadUpsi',
			'getContraceptiveMethods',
			'getSuspectedContractionFailure',
			// stepD
			'getEmployment',
			'getMissedEducation',
			'getDaysMissed',
			'getLiving',
			'getOtherLiving',
			'getSupport',
			'getOtherSupport',
			'getIntoxicantsUsed',
			'getInternetTechCurrentlyUsed',
			// stepE
			'getFeeling',
			'getExperiences',
			// ECC
			'getDatesOfSex',
			'getFirstPeriod',
			'getFirstPeriodOther',
			'getNormalCycle',
			'getNormalCycleOther',
			'getTimeOfEarliestSex',
			'getEC',
			'getPregnancyRisk'
		]),
		reasonForVisit() {
			// Check if other option has value
			return this.getOtherReasonForVisit ?
				`${this.getReasonForVisit} ${this.getOtherReasonForVisit}` : this.getReasonForVisit
		},
		ethnicity() {
			// Check if other option has value
			return this.getOtherEthnicity ?
				`${this.getEthnicity}: ${this.getOtherEthnicity}` : this.getEthnicity
		},
		sexuality() {
			// Check if other option has value
			return this.getOtherSexuality ?
				`${this.getSexuality}: ${this.getOtherSexuality}` : this.getSexuality
		},
		preferredPronoun() {
			// Check if other option has value
			return this.getOtherPreferredPronoun ?
				`${this.getPreferredPronoun}: ${this.getOtherPreferredPronoun}` : this.getPreferredPronoun
		},
		living() {
			// Check if other option has value
			return this.getOtherLiving ?
				`${this.getLiving}: ${this.getOtherLiving}` : this.getLiving
		},
		support() {
			// Check if other option has value
			return this.getOtherSupport ?
				`${this.getSupport} ${this.getOtherSupport}` : this.getSupport
		},
		educationData() {
			if ( this.getMissedEducation === 'Yes' ) {
				return true
			}
			return false
		},
		datesOfSexData() {
			if (this.getDatesOfSex.length) {
				return this.getDatesOfSex.split(', ')
			} else {
				return this.getDatesOfSex
			}
		},
		periodData() {
			// Check if other option has value
			return this.getFirstPeriodOther ?
				this.getFirstPeriodOther : this.getFirstPeriod
		},
		cycleData() {
			// Check if other option has value
			return this.getNormalCycleOther ?
				this.getNormalCycleOther : this.getNormalCycle
		},
		timeStamp() {
			/**
			 * Create timestamp from appointmentDate & appointmentTime
			 * Defaults to '00:00' if appointTime is unavailable
			 */
			let timestamp = ''
			const time = this.getAppointmentTime ? this.getAppointmentTime : '00:00'
			const unixTimestamp = new Date(`${this.getAppointmentDate}T${time}:00`);
			const epochTimestamp = Math.floor(unixTimestamp / 1000)
			timestamp = epochTimestamp
			return timestamp
		}
	},
	methods: {
		async createEntry() {
			// Check for offline connection
			if (!window.navigator.onLine) {
				this.error = 'Error: No internet connection'
				this.$store.commit('loading', false)
				return
			}

			// Variables
			const brook = {
				url: process.env.VUE_APP_BROOK_ENDPOINT
			}

			// Assign all stored form data to object
			const formData = {
				clinic: this.getClinic,
				appointment_timestamp: this.timeStamp,
				reason_for_visit: this.reasonForVisit,	
				first_name: this.getFirstName,
				last_name: this.getLastName,
				preferred_name: this.getPreferredName,
				date_of_birth: this.getDateOfBirth,
				postcode: this.getFullPostcode,
				contact_number: this.getContactNumber,
				ethnicity: this.ethnicity,
				sexuality: this.sexuality,
				sex_at_birth: this.getSexAssignedAtBirth,
				preferred_pronoun: this.preferredPronoun,
				relationship_status: this.getPartners,
				age_of_partner: this.getPartnersAge,
				person_genitals: this.getGenitals,
				has_had_sex: this.getHasHadSex,
				has_had_upsi: this.getHasHadUpsi,
				contraceptive_methods: this.getContraceptiveMethods,
				suspected_contraception_failure: this.getSuspectedContractionFailure,
				employment_status: this.getEmployment,
				has_missed_school_days: this.educationData,
				number_of_school_days_missed: this.getDaysMissed,
				living_with: this.living,
				day_to_day_support: this.support,
				intoxicants_used: this.getIntoxicantsUsed,
				internet_tech_currently_used: this.getInternetTechCurrentlyUsed,
				feeling_today: this.getFeeling,
				have_you_experienced: this.getExperiences,
				upsi_dates: this.datesOfSexData,
				pregnancy_risk: this.getPregnancyRisk,
				ec_eligibility: this.getEC,
				upsi_date: this.getTimeOfEarliestSex,
				run_safeguarding: 1,
				XDEBUG_SESSION_START: 1
			}

			// If first period has value include the data
			if (this.getFirstPeriod) {
				formData.period_first_day = this.getFirstPeriod
			}

			// If normal cycle has value include the data
			if (this.getNormalCycle) {
				formData.cycle_length = this.getNormalCycle
			}

			// Post entry
			try {
				const result = await this.$axios.post(
					`${brook.url}/v1/entries`, formData
				)

				if ( result.status === 200 ) {	
					// Remove loading state
					this.$store.commit('loading', false)

					// Proceed to thank you page
					await this.$router.push(
						this.$GLOBALS.PATH_THANK_YOU
					)
				}
			} catch(error) {
				// Remove loading state
				this.$store.commit('loading', false)

				if (error.result) {
					// The request was made and the server responded
					this.error = error.result.data['meta']['error']['error_message']
				} else if (error.request) {
					//The request was made but no response was received,
					let errorMessage = JSON.parse(error.request.response)
					errorMessage = errorMessage['meta']['error']['error_message']
					this.error = errorMessage
				} else {
					// Something happened in setting up the request and triggered an Error
					this.error = error.message
				}
			}
		}
	}
}