<template>
	<input
		class="custom-control-input"
		type="radio"
		:name="name"
		:id="id"
		:checked="isChecked"
		@change="change"
	/>
</template>
<script>
export default {
  name: "radio",
  model: {
    prop: "checked",
    event: "change"
  },
  props: {
		checked: {
			type: [Boolean, String, Array]
		},
		value: {
			type: [Array, Object, String]
		},
		id: {
			type: String
		},
		update: {
			type: String
		},
		name: {
			type: String
		}
	},
  computed: {
    isChecked() {
      return this.checked === this.value
    }
  },
  methods: {
    change() {
			this.$emit("change", this.value)
			this.$store.commit(this.update, this.value)
    }
  }
}
</script>
