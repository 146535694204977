<template>
	<fieldset v-if="!hidden" class="form-group w-100">
		<legend v-if="title" class="font-weight-bold">{{ title }} <span v-if="required" class="req text-danger">*</span></legend>
		<p v-if="subtitle" class="small">{{ subtitle }}</p>

		<label
			v-if="singleLabel"
			:for="name"
		>{{ singleLabel }} <span v-if="required" class="text-danger">*</span></label>
		
		<div ref="radio" class="form-row form-row__radio">
			<div
				v-for="(item, index) in values"
				:key="index"
				class="custom-control custom-radio col-12 col-md-3"
			>
				<Radio
					:key="index"
					v-model="selected"
					:value="item.name"
					:id="item.id"
					:update="updateMethod"
					:name="name"
				/>
				<label
					:for="item.id"
					class="custom-control-label"
					:class="small ? 'custom-control-label__small' : null"
				>
					<div v-if="item.image" class="custom-control-image">
						<inline-svg
							:src="require(`@/assets/images/svg/${item.image}-icon.svg`)"
							width="60"
							height="60"
							:aria-label="item.name"
							:title="item.name"
						/>
					</div>
					<span class="w-100">{{ item.name }}</span>
				</label>
			</div>
		</div>

		<Alert
			error
			type="danger"
			v-show="getErrors[`${name}`]"
			:class="getErrors[`${name}`] ? 'invalid-error' : null"
		>
			<small>{{ getErrors[`${name}`] }}</small>
		</Alert>
	</fieldset>
</template>

<script>
// State
import { mapGetters } from 'vuex'

// Svg & polyfill
import('@/../node_modules/innersvg-polyfill')
import InlineSvg from 'vue-inline-svg'

// Radio Components
import Radio from './input'
import Alert from '@/components/general/alert'

export default {
	data() {
		return {
			selected: '',
			get: this.getter
		}
	},
	components: {
		Radio,
		Alert,
		InlineSvg
	},
	props: {
		required: {
			type: Boolean,
			default: false
		},
		title: {
			type: String,
			default: ''
		},
		subtitle: {
			type: String,
			default: ''
		},
		small: {
			default: false,
			type: Boolean
		},
		values: {
			type: [Array, Object, String]
		},
		name: {
			type: String
		},
		singleLabel: {
			default: '',
			type: String
		},
		updateMethod: {
			type: String
		},
		getter: {
			type: String
		},
		hidden: {
			default: false,
			type: Boolean
		},
	},
	computed: {
		state() {
			const get = this.getter
			const data = this.$store.getters[get]
			return data
		},
		...mapGetters([
			'getErrors'
		]),
	},
	watch: {
		state(value) {
			// Remove set error
			if ( value ) delete this.getErrors[`${this.name}`]
			// Set value
			this.selected = value
		}
	},
	methods: {
		checkState() {
			// Check stored state
			const get = this.getter
			const data = this.$store.getters[get]

			if ( data && data.length ) {
				this.selected = data
			}
		},
		multiRadio() {
			let parent = this.$refs.radio
			let inputs = parent.querySelectorAll('.custom-radio')

			// Checks if inputs within form-row are more than 4.
			if ( inputs && inputs.length > 4 ) {	
				// Adds class to set flex-basis to custom-radio to avoid multi-line.
				parent.classList.add('custom-radio__multi')
				
				// Flex basis based on iunputs length
				let basis = 100 / inputs.length

				// Loop through to give each input a flex basis
				for (let i = 0; i < inputs.length; i++) {
					inputs[i].style.flexBasis = `${basis}%`
				}
			}
		}
	},
	mounted() {
		this.checkState()
		this.multiRadio()
	}
}
</script>