<template>
  <div>
		<page-header
			title="Pregnancy risk"
		>
			<template v-slot:content>
				Because you have indicated that you have had unprotected sex, we are going to ask some additional questions to assess your risk of pregnancy. Please don't worry if you're not sure about some of the answers. Our nurses will be able to advise during your consultation. You'll see our assessment of your risk at the end of this tool.
			</template>
		</page-header>
		<page-container>
			<form
				autocomplete="off"
				@submit.prevent="validateForm"
			>

				<!-- ECC Question 01 -->
				<Radios
					required
					:values="sex"
					name="hasHadSex"
					getter="getHasDatesOfSex"
					updateMethod="updateHasHadSex"
					title="Have you had penis in vagina sex without a condom in the last month?"
				/>
				
				<form-row
					v-if="hasHadSex"
					required
					title="Select all of the days you have had sex in the past month"
					subtitle="Use your best guess if you’re not sure. (select all days you had sex)"
				>
					<form-date
						required
						mode="multiple"
						maxDate="today"
						sronly
						label="When have you had penis in vagina sex without a condom in the last month?"
						placeholder="Select date(s)"
						dataStore="ecc"
						name="datesOfSex"
						updateMethod="updateDatesOfSex"
					/>
				</form-row>
				
				<!-- ECC Question 02 -->
				<form-row
					required
					v-if="hasHadSex"
					title="When was the first day of your last period?"
					subtitle="In other words, the first day you started bleeding (don’t worry if you can’t remember)"
				>
					<form-date
						rules="required"
						mode="single"
						maxDate="today"
						sronly
						label="When was the first day of your last period?"
						placeholder="Select date"
						name="firstPeriod"
						dataStore="ecc"
						updateMethod="updateFirstPeriod"
					/>
				</form-row>
				<!-- Period option other -->
				<Radios
					v-if="hasHadSex"
					:values="periodOptions"
					small
					name="firstPeriodOther"
					getter="getFirstPeriodOther"
					updateMethod="updateFirstPeriodOther"
				/>

				<!-- ECC Question 03 -->
				<form-row
					required
					v-if="hasHadSex"
					title="What is your normal cycle length?"
					subtitle="This is measured from the first day of your last period (so when you started bleeding) to the first day of your next period. If you don’t know, tell us what you think would be the shortest cycle length."
				>
					<form-input
						sronly
						small
						prepend="days"
						placeholder="My normal cycle length is XX days"
						name="normalCycle"
						dataStore="ecc"
						updateMethod="updateNormalCycle"
						label="When was the first day of your last period?"
					/>
				</form-row>
				<Radios
					v-if="hasHadSex"
					:values="cycleOptions"
					small
					name="normalCycleOther"
					getter="getNormalCycleOther"
					updateMethod="updateNormalCycleOther"
				/>

				<!-- ECC Question 04 -->
				<form-row
					required
					v-if="hasHadSex && hasEarliestDay" 
					:title="`What time on the ${date} did you have sex?`"
				>
					<form-time
						sronly
						:label="`What time on this ${date} day did you have sex?`"
						name="timeOfEarliestSex"
						dataStore="ecc"
						updateMethod="updateTimeOfEarliestSex"
					/>
				</form-row>

				<alert error v-if="error" type="danger">
					<small>{{ error }}</small>
				</alert>
				
				<form-submit
					:title="hasEarliestDay ? 'Finish' : 'Continue'"
				/>
			</form>
		</page-container>
  </div>
</template>

<script>
// Mixins
import createEntry from '@/mixins/create-entry'
import scrollToError from '@/mixins/scroll-to-error'
import mixpanelTracking from '@/mixins/mixpanel'

// State
import { mapGetters } from 'vuex'

// Form Components
import pageHeader from '@/components/general/page-header'
import pageContainer from '@/components/general/page-container'
import formRow from '@/components/form/form-row'
import formInput from '@/components/form/form-input'
import formDate from '@/components/form/form-date'
import formTime from '@/components/form/form-time'
import formSubmit from '@/components/form/form-submit'

import Radios from '@/components/form/radio/group'

// General Components
import alert from '@/components/general/alert'

export default {
	mixins: [ createEntry, scrollToError, mixpanelTracking ],
	components: {
		pageHeader,
		pageContainer,
		formRow,
		formInput,
		formSubmit,
		formDate,
		formTime,
		alert,
		Radios
	},
	data() {
		return {
			error: '',
			hasHadSex: null,
			dontKnowPeriod: '',
			normalCycle: '',
			hasEarliestDay: false,
			earliestDay: '',
			hasCalculatedEC: false,
			hasCalculatedPR: false,
			ec: '',
			pregnancyRisk: '',
			sex: [
				{
					name: "I have",
					id: "i-have-had-sex"
				},
				{
					name: "I haven't",
					id: "i-havent-had-sex"
				}
			],
			periodOptions: [
				{
					name: "I don't know",
					id: "i-dont-know-period"
				}
			],
			cycleOptions: [
				{
					name: "I'm not sure",
					id: "dont-know-cycle"
				}, {
					name: "It varies as my periods are irregular",
					id: "varies-cycle"
				}
			]
		}
	},
	computed: {
		date() {
			let date = ''
			const d = new Date(this.earliestDay)
			const day = d.getDate()
			const ordinal = (
				31 == day || 21 == day || 1 == day ? "st" :
				22 == day || 2 == day ? "nd" :
				23 == day || 3 == day ? "rd" :
				"th"
			)
			const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
			const month = months[d.getMonth()]

			if ( this.earliestDay ) date = `${day}${ordinal} ${month}`

			return date
		},
		...mapGetters([
			'getHasDatesOfSex',
			'getDatesOfSex',
			'getFirstPeriod',
			'getFirstPeriodOther',
			'getNormalCycle',
			'getNormalCycleOther',
			'getTimeOfEarliestSex',
		]),
		cycle() {
			// return ''
			if (!this.getNormalCycleOther.length) {
				if (!this.getNormalCycle.length) {
					return 'Please select if you have had penis in vagina sex without a condom in the last month'
				}
				if ( this.getNormalCycle.length && this.getNormalCycle > 20 && this.getNormalCycle < 60) {
					return ''
				} else {
					return 'Please enter a value between 20-60'
				}
			}

			return ''
		},
		period() {
			// return ''
			if (!this.getFirstPeriodOther.length) {
				if (!this.getFirstPeriod.length) {
					return 'Please select the first day of your last period or click the "I don\'t know" option if you are not sure'
				}
			}

			return ''
		},
		upsiDates() {
			let dates = ''

			// Creates array from date string data
			let arrayOfDates = this.getDatesOfSex.split(', ')

			if ( arrayOfDates ) {
				arrayOfDates.map(date => {
					dates += `upsi_dates[]=${date}&`
				})

				// remove last character from string
				dates = dates.slice(0, -1);
			}
			
			return dates
		}
	},
	watch: {
		getHasDatesOfSex(value) {
			if ( value === "I have" ) {
				this.hasHadSex = true
			} else {
				this.hasHadSex = false
			}
		},
		getFirstPeriod(value) {
			if ( value.length ) {
				this.dontKnowPeriod = ''
				if ( this.getFirstPeriodOther.length ) this.$store.dispatch('clearFirstPeriodOther')
			}
		},
		getFirstPeriodOther(value) {
			if ( value === "I don't know" ) {
				if ( this.getFirstPeriod.length ) {
					this.$store.dispatch('clearFirstPeriod')
					this.$store.commit('updateErrors', {
						...this.getErrors,
						datesOfSex: !this.getDatesOfSex.length ? 'Please select if you have had penis in vagina sex without using contraception in the last month' : '',
						firstPeriod: this.period,
						normalCycle: this.cycle
					})
				}
			}
		},
		getNormalCycle(value) {
			if ( value.length ) {
				this.normalCycle = ''
				if ( this.getNormalCycleOther.length ) this.$store.dispatch('clearNormalCycleOther')
			}
		},
		getNormalCycleOther(value) {
			if ( value === this.cycleOptions[0]['name'] || value === this.cycleOptions[1]['name'] ) {
				if ( this.getNormalCycle.length ) {
					this.$store.dispatch('clearNormalCycle')
					this.$store.commit('updateErrors', {
						...this.getErrors,
						datesOfSex: !this.getDatesOfSex.length ? 'Please select if you have had penis in vagina sex without using contraception in the last month' : '',
						firstPeriod: this.period,
						normalCycle: this.cycle
					})
				}
			}
		}
	},
	methods: {
		checkState() {
			// Check stored state
			// Dates of sex
			if ( this.getHasDatesOfSex.length ) {
				this.hasHadSex = true

				if ( this.getHasDatesOfSex === "I have" ) {
					this.hasHadSex = true
				} else {
					this.hasHadSex = false
				}
			}

			// Period state
			if (this.getFirstPeriodOther === "I don't know") {
				this.dontKnowPeriod = "I don't know"
			}

			// Cycle state
			if ( this.getNormalCycleOther === this.cycleOptions[0]['name'] )  {
				this.normalCycle = this.cycleOptions[0]['name']
			}

			if ( this.getNormalCycleOther === this.cycleOptions[1]['name'] )  {
				this.normalCycle = this.cycleOptions[1]['name']
			}

			if ( this.getTimeOfEarliestSex.length )  {
				this.hasEarliestDay = true
			}
		},

		/**
		 * Completes ECC and post entry
		 */
		async completeECC() {
			// Set ECC to complete
			await this.$store.commit('completeEcc', true)

			// Create entry
			await this.createEntry()
		},

		/**
		 * Qualifies Upsi dates based on dates provided
		 * @param upsi_dates array of dates converted vua this.upsiDates
		 * @return earliest date of sex if result is successful else completes ECC
		 */
		async qualifyUpsiDates() {
			const result = await this.$axios.get(
				`${this.$GLOBALS.API_URL}/v1/ecc/qualify-upsi-dates?${this.upsiDates}`
			)

			if ( result.status === 200 ) {
				const earliestDate = result.data.data.earliest_qualifying_date

				if ( earliestDate === null) {
					// Calculate EC & Pregnancy Risk if required data is provided
					if ( this.getFirstPeriod && this.getNormalCycle ) {
						await this.calculateEC()
						await this.calculatePregnancyRisk()
					} else {
						// Assign base pregnancy risk value
						await this.$store.commit('updatePregnancyRisk', 'incalculable')
					}

					// Complete the ECC
					await this.completeECC()

					// Set null values
					this.earliestDay = ''
					this.hasEarliestDay = false
				} else {
					this.earliestDay = earliestDate
					this.hasEarliestDay = true
				}
			}
		},

		/**
		 * Calculates ECCbased on data given
		 * @param upsi_data String of earliest date and time
		 * @param period_first_day Optional day of first period
		 * @param cycle_length Option number of cycle
		 * @return Get EC Data
		 */
		async calculateEC() {
			try {
				const result = await this.$axios.get(
					`${this.$GLOBALS.API_URL}/v1/ecc/emergency-contraception`, {
						params: {
							upsi_datetime: this.earliestDay && this.getTimeOfEarliestSex ? `${this.earliestDay} ${this.getTimeOfEarliestSex}:00` : null,
							period_first_day: this.getFirstPeriod ? this.getFirstPeriod : null,
							cycle_length: this.getNormalCycle ? this.getNormalCycle : null
						}
					}
				)

				if ( result.status === 200 ) {
					// Set Risk variables
					this.hasCalculatedEC = true
					await this.$store.commit('updateEC', result.data.data.ec)
				}
			} catch(error) {
				// Error
				this.$store.commit('loading', false)

				if (error.result) {
					// The request was made and the server responded
					this.error = error.result.data['meta']['error']['error_message']
				} else if (error.request) {
					//The request was made but no response was received,
					let errorMessage = JSON.parse(error.request.response)
					errorMessage = errorMessage['meta']['error']['error_message']
					this.error = errorMessage
				} else {
					// Something happened in setting up the request and triggered an Error
					this.error = error.message
				}
			}
		},

		/**
		 * Calculates Pregnancy Risk based on data given
		 * @param upsi_dates array of dates converted vua this.upsiDates
		 * @param period_first_day Optional day of first period
		 * @param cycle_length Option number of cycle
		 * @return Get Pregnancy risk Data
		 */
		async calculatePregnancyRisk() {
			if ( this.getFirstPeriod && this.getNormalCycle ) {
				try {
					const result = await this.$axios.get(
						`${this.$GLOBALS.API_URL}/v1/ecc/pregnancy-risk?${this.upsiDates}`, {
							params: {
								period_first_day: this.getFirstPeriod,
								cycle_length: this.getNormalCycle
							}
						}
					)

					if ( result.status === 200 ) {
						// Set Risk variables
						this.hasCalculatedPR = true
						await this.$store.commit('updatePregnancyRisk', result.data.data.pregnancy_risk)
					}
				} catch(error) {
					// Error
					this.$store.commit('loading', false)

					if (error.result) {
						// The request was made and the server responded
						this.error = error.result.data['meta']['error']['error_message']
					} else if (error.request) {
						//The request was made but no response was received,
						let errorMessage = JSON.parse(error.request.response)
						errorMessage = errorMessage['meta']['error']['error_message']
						this.error = errorMessage
					} else {
						// Something happened in setting up the request and triggered an Error
						this.error = error.message
					}
				}
			} else {
				this.hasCalculatedPR = true
				await this.$store.commit('updatePregnancyRisk', 'incalculable')
			}
		},

		/**
		 * Validations of ECC
		 * Multi step conditions
		 */
		async validateForm() {
			// Clear loading state
			this.$store.commit('loading', false)
			
			if ( this.getHasDatesOfSex ) {
				// Quit ECC and move on to submission if user answers: "I haven't"
				if ( this.getHasDatesOfSex === "I haven't") {
					await this.completeECC()
				}

				// Submit for earliest day first
				if (
					this.getDatesOfSex.length &&
					(!!this.getFirstPeriod || !!this.getFirstPeriodOther) &&
					(!!this.getNormalCycle || !!this.getNormalCycleOther)
				) {
					if ( !this.hasEarliestDay ) {
						await this.qualifyUpsiDates()
					} else {
						if ( this.getTimeOfEarliestSex ) {
							await this.calculateEC()
							await this.calculatePregnancyRisk()

							if ( this.hasCalculatedEC && this.hasCalculatedPR ) {
								await this.completeECC()
							}
						} else {
							// Set errors
							await this.$store.commit('updateErrors', {
								...this.getErrors,
								timeOfEarliestSex: !this.getTimeOfEarliestSex.length ? 'Please enter time of earliest sex' : ''
							})

							// Scroll to error
							await this.scrollToError()
						}
					}
				} else {
					// Set errors
					await this.$store.commit('updateErrors', {
						...this.getErrors,
						datesOfSex: !this.getDatesOfSex.length ? 'Please select if you have had penis in vagina sex without using contraception in the last month' : '',
						firstPeriod: this.period,
						normalCycle: this.cycle
					})

					// Scroll to error
					await this.scrollToError()
				}
			} else {
				// Set errors
				await this.$store.commit('updateErrors', {
					...this.getErrors,
					hasHadSex: !this.getHasDatesOfSex.length ? 'Please select if you have had penis in vagina sex without using contraception in the last month' : ''
				})

				// Scroll to error
				await this.scrollToError()
			}
		}
	},
	mounted() {
		this.checkState()
	},
}
</script>
